

import React from "react";
import WebAppsComponent from "../component/Services/webAppsComponent";
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import ServicesHomeComponent from "../component/Services/servicesHomeComponent";
import Layout from "../component/layout";
import ServicesImage from '../images/services_banner-min.jpg'

const WebAppsDevelopement = (props) =>{
    const {
        data:{
            wpPost:{
                seo,
                serviciesHeader: {
                  servicesHomeTitle,
                  servicesHomeDescription,
                },
            },
        },
        pageContext: { title },
    } = props;
    return(
      <Layout seo={seo} title={title}>
        <ServicesHomeComponent
          servicesBackground={ServicesImage}
          servicesHomeTitle={servicesHomeTitle}
          servicesDescription={servicesHomeDescription}
          isProcess={true}
        />
        <WebAppsComponent/>
        <FooterComponent/>
      </Layout>
    )
}
export const query = graphql`
  query {
    wpPost(slug: {eq: "web-app-development-page"}) {
      seo {
        metaDesc
        title
      }
      serviciesHeader {
        servicesHomeTitle
        servicesHomeDescription
      }
    }
}
`;
export default WebAppsDevelopement