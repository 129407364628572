import { Link } from "gatsby";
import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'
import MototechLogo from '../../../static/assets/Header/motomtech.svg'
const WebAppsComponent = () =>{
    return(
        <div>
        <div className="web-apps-section">
            <div className="industry-section-wrapper web-apps">
                <div className="home-section-textIcone web-apps-text">
                        <TitleIcone className="home-section-titleIcone"/>
                        <h2 className="web-apps-section-title">Progressive Web Apps are the solution for you if you’re looking for an application that: </h2>
                </div>
                <ul>
                    <li className="web-apps-section-list">Is able to be built up continuously following the ever-changing requirements and market demands. </li>
                    <li className="web-apps-section-list">Has a particular UI design that fits all devices thanks to CSS3 media queries fluid grid concepts and flexible images.</li>
                    <li className="web-apps-section-list">Gives the feel of a native app with a splash screen.</li>
                    <li className="web-apps-section-list">Is fresh, safe, and designed to adhere to today's market’s needs.</li>
                    <li className="web-apps-section-list">Has lower friction being a product of the web and is faster and cheaper to develop.</li>
                </ul>
            </div>
            <div className="full-container">
            <div className="web-apps-section-wrapper">
            <div className="home-section-textIcone">
                        <TitleIcone className="home-section-titleIcone web-apps-icon"/>
                        <div className="web-apps-section-text">
                            <p className="web-apps-section-description">Our Progressive web apps are the only ones that enable testing of an app before installation, due to being accessible in web browsers. </p>
                            <p className="web-apps-section-description">Together with the full-screen experience of progressive web apps, the installation prompt can be considered an advance in the unification of end-user experience.</p>
                            <p className="web-apps-section-description">Our team offers 100% transparency. You will be in the loop throughout the whole application development process. </p>
                            <p className="web-apps-section-description">Our developers will ensure your valuable inputs and ideas are carefully analyzed and considered so we can build an impeccable app. </p>
                            <p className="web-apps-section-description">Progressive Web Apps are very in demand and the competition has made us deliver a fully-functional app within the decided time period. </p>
                        </div>
                </div>
            </div>
            </div>
        </div>
        <div className="who-we-work-section">
            <MototechLogo/>
            <h5 className="who-we-work-section-title">Who we work with</h5>
            <p className="who-we-work-section-description">We are a one-stop-shop experience that can handle any challenge during the development process. This is why we are able to work with Startups, Entrepreneurs, and Established companies with our knowledgeability and sensitivity to our client's needs.</p>
            <p className="who-we-work-section-quote">“Let’s build a great digital product”</p>
            <Link className="who-we-work-section-link" to="/contact">Contact Us</Link>
        </div>
        </div>
    )
}
export default WebAppsComponent